import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Img from 'gatsby-image';
import { Disqus } from 'gatsby-plugin-disqus';

import { usePageUrl } from 'hooks/usePageUrl';

import Layout from 'components/utils/layout';
import GridView from 'components/utils/gridView';
import SEO from 'components/utils/seo';
import Button from 'components/atoms/button';
import Excerpt from 'components/atoms/excerpt';
import PostNav from 'components/modules/postNav';
import ShareSocial from 'components/modules/shareSocial';

import { useImg } from 'hooks/useImg';

const StyledArticle = styled.article`
  margin-bottom: 10rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: left;
    margin: 7rem 0 2rem;
    line-height: 1.3;
  }
  h1 {
    font-style: italic;
    ${({ theme: { mq } }) => mq.bigPhone} {
      margin-top: 10rem;
    }
  }
  p {
    line-height: 1.5;
    margin-bottom: 2rem;
    font-size: ${({ theme }) => theme.fontSize.blogM};
    letter-spacing: 1.2px;

    & span {
      display: block;
      border: 3px solid ${({ theme }) => theme.color.devider};
      box-shadow: ${({ theme }) => theme.shadow.borderShadowLight};
      max-width: 710px !important;
      border-radius: 2rem;
      overflow: hidden;
      pointer-events: none;
    }

    a:link,
    a:visited {
      color: ${({ theme }) => theme.color.active};
      font-style: italic;

      :hover {
        text-decoration: underline;
      }
    }
  }

  ul,
  ol {
    margin-left: 5rem;
    line-height: 1.5;
    font-size: ${({ theme: { fontSize } }) => fontSize.blogM};
  }

  & > ul,
  & > ol {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }

  blockquote {
    margin: 5rem auto 5rem;
    width: 100%;
    position: relative;
    padding: 2rem 2rem 0.05rem;
    color: ${({ theme }) => theme.color.light};
    font-style: italic;
    background-color: ${({ theme }) => theme.color.devider};
    border-radius: 2rem;

    ::before,
    ::after {
      content: '"';
      position: absolute;
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      font-size: 10rem;
    }

    ::before {
      top: -20px;
      left: -3%;
    }
    ::after {
      bottom: -60px;
      right: -1%;
      ${({ theme: { mq } }) => mq.tablet} {
        bottom: -40px;
      }
    }

    & > * {
      font-size: ${({ theme }) => theme.fontSize.m};
      line-height: 1.3;
    }
  }
`;

const StyledImg = styled(Img)`
  border-radius: 2rem;
`;

const PostInfo = styled.p`
  margin-top: 1rem;
  font-style: italic;
  font-size: ${({ theme: { fontSize } }) => fontSize.s} !important;
  color: ${({ theme }) => theme.color.darkFont};
  text-align: right;

  ${({ theme: { mq } }) => mq.bigPhone} {
    font-size: ${({ theme: { fontSize } }) => fontSize.xs} !important;
  }
`;

const StyledButton = styled(Button)`
  display: inline-block;
  margin: 10rem auto;
  width: 28.5rem;
`;

const StyledExcerpt = styled(Excerpt)`
  font-size: ${({ theme: { fontSize } }) => fontSize.s} !important;
  margin-top: 10rem;
  margin-bottom: 10rem;
`;

const Post = ({ data, pageContext }) => {
  const { mdx } = data;
  const defaultImage = useImg();

  let disqusConfig = {
    url: usePageUrl(),
    identifier: mdx.id,
    title: mdx.frontmatter.title,
  };

  return (
    <Layout>
      <SEO
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description}
      />
      <GridView page='post'>
        <StyledArticle>
          <h1>{mdx.frontmatter.title}</h1>

          {mdx.frontmatter.image ? (
            <StyledImg fluid={mdx.frontmatter.image.childImageSharp.fluid} />
          ) : (
            <StyledImg fluid={defaultImage.defaultImg} />
          )}

          <PostInfo>
            {`${mdx.frontmatter.authors.map(
              author => author.author,
            )}, ${mdx.frontmatter.date.substring(0, 10)}`}{' '}
            || {mdx.frontmatter.tags.map(tag => `#${tag} `)}
          </PostInfo>
          <StyledExcerpt pagetype='post' bgcolor={'#efefef0d'}>
            {mdx.frontmatter.description}
          </StyledExcerpt>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </StyledArticle>
        <ShareSocial slug={pageContext.slug} />
        <PostNav pageContext={pageContext} />
        <Disqus config={disqusConfig} />
        <StyledButton wide to='/blog'>
          do wszystkich artykułów
        </StyledButton>
      </GridView>
    </Layout>
  );
};

export const query = graphql`
  query BlogPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title
        date
        description
        authors {
          author
        }
        tags
        image {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      excerpt
      body
    }
  }
`;

export default Post;
