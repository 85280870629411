import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import LeftArrowImg from 'images/svg/leftArrow.svg';
import RightArrowImg from 'images/svg/rightArrow.svg';

const Wrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0rem 1rem 10rem;
  padding-top: 5rem;
  list-style: none;
  border-top: 1px solid ${({ theme: { color } }) => color.devider};

  li {
    margin-top: 0;
  }

  ${({ theme: { mq } }) => mq.tablet} {
    margin: 15rem 1rem 10rem;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  transition: color 0.25s;
  font-size: ${({ theme: { fontSize } }) => fontSize.blogM};

  :hover {
    color: ${({ theme }) => theme.color.active};
  }

  span {
    margin: 0 2.5rem;
  }
  svg {
    margin: 0;
    fill: ${({ theme }) => theme.color.active};
  }
`;

const PostNav = ({ pageContext }) => {
  const { previous, next } = pageContext;
  const slug = direction => `/blog${direction.fields.slug}`;
  return (
    <Wrapper>
      <li>
        {previous && (
          <StyledLink to={slug(previous)} rel='prev'>
            <LeftArrowImg /> <span>Poprzedni post</span>
          </StyledLink>
        )}
      </li>
      <li>
        {next && (
          <StyledLink to={slug(next)} rel='next'>
            <span>Następny post</span> <RightArrowImg />
          </StyledLink>
        )}
      </li>
    </Wrapper>
  );
};

export default PostNav;
