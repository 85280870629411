import React from 'react';
import styled from 'styled-components';

import FacebookIcon from 'images/svg/fb-icon.svg';
import LinkedInIcon from 'images/svg/linkedin-icon.svg';
import TwitterIcon from 'images/svg/twitter-icon.svg';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  & * {
    margin-top: 0;
  }

  button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    transition: transform 0.2s;
    :active {
      transform: translateY(3px);
    }
  }

  svg {
    margin-left: 3rem;
    margin-bottom: 1rem;
    height: 30px;
    width: 30px;
    fill: ${({ theme: { color } }) => color.active};
    transition: 0.25s all;
    :hover {
      transform: scale(1.1);
    }
  }
`;

const ShareSocial = ({ slug }) => {
  const url = `https://inspiring-allen-247d83.netlify.app/blog${slug}`;
  const facebookSharer = 'https://www.facebook.com/sharer/sharer.php?u=';
  const linkedinSharer = 'https://www.linkedin.com/sharing/share-offsite/?url=';
  const twitterSharer = 'https://www.twitter.com/share?url=';

  const handleClick = (sharer, postUrl) => {
    window.open(
      `${sharer + postUrl}`,
      'DescriptiveWindowName',
      'resizable=yes,scrollbars=yes,status=yes',
    );
  };
  return (
    <Wrapper>
      <p>Udostępnij:</p>
      <button onClick={() => handleClick(facebookSharer, url)}>
        <FacebookIcon />
      </button>
      <button onClick={() => handleClick(linkedinSharer, url)}>
        <LinkedInIcon />
      </button>
      <button onClick={() => handleClick(twitterSharer, url)}>
        <TwitterIcon />
      </button>
    </Wrapper>
  );
};
export default ShareSocial;
